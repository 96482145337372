import React, { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import Table from "../../components/TableComponent";
import { devInstance } from "../../store/devInstance";
import Loader from "../../components/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { formatter } from "../../helper";
import closeModal from "../../assets/images/close-modal.svg";
import Button from "../../components/ButtonComponent";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Table2 from "../../components/CustomersTable.tsx";
import Modal2 from "../../components/Modal";
import DetailsModal from "../../components/DetailsModal";
import { loginUser } from "../../store/auth-slice";

const AdminScreen = () => {
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [enquiries, setEnquiries] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionsLength, setTransactionsLength] = useState(0);
    const [customerLength, setCustomerLength] = useState(0);
    const [products, setProducts] = useState([]);
    const { token }: any = useAppSelector((state) => state.auth);
    const [menu, setMenu] = useState(false);
    const [news, setNews] = useState([]);
    const [transaction, setTransaction] = useState<any>({});
    const [modal2, setModal2] = useState(false);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const [modal3, setModal3] = useState(false);
    const [cid, setCid] = useState<null | number>(null);
    const [customer, setCustomer] = useState<any>({});
    const [menu2, setMenu2] = useState(false);
    const [investments, setInvestments] = useState<any>([]);
    const [investmentId, setInvestmentId] = useState<any>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoading(true);
        // devInstance
        //     .get(
        //         "https://apps.dlm.group/ASSETMGTAPI/api/v1/Admin/GetAllCustomers"
        //     )
        //     .then((response: any) => {
        //         setCustomers(response.data.data.pageItems);
        //         setCustomerLength(response.data.data.totalNumberOfItems);
        //     })
        //     .catch((err) => console.log(err))
        //     .finally(() => {
        //         setLoading(false);
        //     });

        // devInstance
        //     .get(
        //         "https://apps.dlm.group/ASSETMGTAPI/api/v1/Transaction/GetAllInquiries"
        //     )
        //     .then((response: any) => {
        //         setEnquiries(response.data.data.pageItems);
        //     })
        //     .catch((err) => console.log(err))
        //     .finally(() => {
        //         setLoading(false);
        //     });

        // devInstance
        //     .get(
        //         "https://apps.dlm.group/ASSETMGTAPI/api/v1/Admin/GetNewsUpdates"
        //     )
        //     .then((response: any) => {
        //         setNews(response.data);
        //         // console.log(response.data)
        //     })
        //     .catch((err) => console.log(err))
        //     .finally(() => {
        //         setLoading(false);
        //     });

        // devInstance
        //     .get(
        //         "https://apps.dlm.group/ASSETMGTAPI/api/v1/Admin/GetProductIds"
        //     )
        //     .then((response: any) => {
        //         setProducts(response.data.data);
        //         // console.log(response.data)
        //     })
        //     .catch((err) => console.log(err))
        //     .finally(() => {
        //         setLoading(false);`
        //     });

        devInstance("https://assetmgt-api.dlm.group/api/v1/investments")
            // .then((response: any) => response.json)
            .then((response) => {
                setInvestments(response?.data?.data?.investments);
                // setTransactionsLength(
                //     response?.data?.data?.results
                // );
                console.log(response, "investmentTest 01");
            })

            .catch((err) => console.log(err, "error"))
            .finally(() => {
                setLoading(false);
            });

        devInstance
            .get("https://assetmgt-api.dlm.group/api/v1/news/news-update")
            .then((response: any) => {
                setNews(response?.data?.data?.news);
                console.log(response, "investmentTest 01");
            })
            .catch((err) => {
                toast.error(`${err?.message}`);
                setLoading(false);
            })
            .finally(() => setLoading(false));

        getProducts();
    }, []);

    async function getProducts() {
        let data: any = new FormData();
        data.append("username", "apiuser-asset");
        data.append("password", "d*gj5jYM@aSseT");

        let res: any = await dispatch(loginUser(data));

        let errors =
            res.meta.rejectedWithValue === true ||
            res.meta.requestStatus === "rejected";

        if (!errors) {
            devInstance
                .get(
                    "/order/terminstrumenttype/list/active"
                )
                .then((res) => {
                    setProducts(res?.data?.result.slice(0, 9));
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false));
        }
    }

    // const getProdId = (productId: any) => {
    //     return products.find((el: any) => productId === el.productId);
    // };

    // function getCustomerDetails(id: number) {
    //     setLoading(true);
    //     devInstance
    //         .get(`/Admin/GetCustomerInfoAndTransactions/${id}`)
    //         .then((res: any) => {
    //             setCustomer(res?.data);
    //             setCid(id);
    //             console.log(res, "transaction");
    //             setModal(true);
    //         })
    //         .catch((err: any) => toast(`${err.response.data || err.message}`))
    //         .finally(() => setLoading(false));
    // }

    // function getDetail(id: number) {
    //     setInvestmentId(id);
    // }

    // const TransactionList = () => {
    //     if (investments?.length > 0) {
    //         return (
    //             <>
    //                 {investments
    //                     ?.slice(0, 9)
    //                     .map((item: any, index: number) => (
    //                         <div className="flex items-center">
    //                             <div className="basis-1/4 pl-[20px]">
    //                                 <h3>{item?.instrumentTypeLabel}</h3>
    //                             </div>
    //                             <div className="basis-1/4 text-center">
    //                                 <h3>{formatter(item?.faceValue)}</h3>
    //                             </div>
    //                             <div className="basis-1/4 text-center">
    //                                 <h3>{item?.instrumentType}</h3>
    //                             </div>
    //                             <div className="basis-1/4 text-center">
    //                                 <h3>{item?.id}</h3>
    //                             </div>
    //                             <div className="basis-1/4 text-center">
    //                                 <h3>{item?.customerId}</h3>
    //                             </div>
    //                             <div className="basis-1/4 flex justify-end pr-[10px] text-white-lighter">
    //                                 <div
    //                                     className={`basis-1/4 text-right relative flex justify-end items-center`}
    //                                 >
    //                                     {/* <div className="absolute bg-white-lighter border border-primary/30 p-3 w-80 rounded"></div> */}
    //                                     <h3
    //                                         className={`${
    //                                             item?.status.toLowerCase() ===
    //                                             "approved"
    //                                                 ? "text-success"
    //                                                 : item?.status.toLowerCase() ===
    //                                                   "pending"
    //                                                 ? "text-primary"
    //                                                 : "text-error"
    //                                         } capitalize w-28 cursor-pointer text-sm`}
    //                                         // onClick={() => {
    //                                         //     // setModalStatus(
    //                                         //     //     item?.transactionStatus.toLowerCase()
    //                                         //     // );
    //                                         //     // setOpenModal(true);
    //                                         // }}
    //                                     >
    //                                         <span>
    //                                             {item?.status.toLowerCase() ===
    //                                             "approved"
    //                                                 ? "successful"
    //                                                 : item?.status}
    //                                         </span>
    //                                     </h3>
    //                                     <svg
    //                                         xmlns="http://www.w3.org/2000/svg"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         strokeWidth={1.5}
    //                                         stroke="#09335E"
    //                                         className="w-6 h-6 cursor-pointer"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
    //                                         />
    //                                     </svg>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     ))}
    //             </>
    //         );
    //     } else {
    //         return (
    //             <div className="pl-[20px] text-center text-error">
    //                 <h3>No Transactions Found</h3>
    //             </div>
    //         );
    //     }
    // };

    const fetchTransactions = useCallback((pageNumber?: number) => {
        setLoading(true);
        devInstance
            .get("https://assetmgt-api.dlm.group/api/v1/investments")
            .then((response: any) => {
                setInvestments(response?.data?.data?.investments);
                console.log(response, "investmentTest 01");
            })
            .catch((err) => {
                toast.error(`${err?.message}`);
                setLoading(false);
            })
            .finally(() => setLoading(false));
    }, []);

    async function approveReq(investment: any) {
        setLoading(true);
        let data: any = new FormData();
        data.append("username", "apiuser-asset");
        data.append("password", "d*gj5jYM@aSseT");

        let res: any = await dispatch(loginUser(data));

        let errors =
            res.meta.rejectedWithValue === true ||
            res.meta.requestStatus === "rejected";

        if (!errors) {
            devInstance(
                "/order/terminstrument/submit",
                {
                    method: "POST",
                    data: {
                        customerId: investment.customerId,
                        instrumentTypeName: investment.instrumentTypeName,
                        instrumentTypeLabel: investment.instrumentTypeLabel,
                        faceValue: investment.faceValue,
                        currency: "NGN",
                        startDate: new Date(),
                        tenure: "365",
                        currentRate: "14",
                        autoRollover: "false",
                    },
                }
            )
                .then((response: any) => {
                    console.log(response.data);
                    devInstance
                        .put(
                            `/order/terminstrument/post/${response.data.msgArgs[0]}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(async () => {
                            const resp = await devInstance.post(
                                "https://assetmgt-api.dlm.group/api/v1/investments/approve-investment",
                                {
                                    id: investment.id,
                                }
                            );
                            if (resp) {
                                toast.success(
                                    "Investment was successfully Approved"
                                );
                                setMenu(false);
                                fetchTransactions();
                            }
                        })
                        .catch((err) => {
                            toast.error(`${err.message}`);
                        });
                })
                .catch((err) => {
                    toast.error(`${err.message}`);
                })
                .finally(() => setLoading(false));
        }

        // devInstance
        //     .post(`https://assetmgt-api.dlm.group/api/v1/investments/approve-investment`, {
        //         id,
        //         startDate: new Date(),
        //         currentRate: "14",
        //         tenure: "365",
        //         autoRollover: "false",
        //     })
        //     .then((response: any) => {
        //         toast.success("Investment was successfully Approved");
        //         setMenu(false);
        //         fetchTransactions();
        //     })
        //     .catch((err) => {
        //         toast.error(`${err.message}`);
        //     })
        //     .finally(() => setLoading(false));
    }

    function declineReq(id: number) {
        setLoading(true);
        devInstance
            .put(`https://assetmgt-api.dlm.group/api/v1/investments/${id}`)
            .then((response: any) => {
                toast.success("Investment was successfully Declined");
                fetchTransactions();
                setMenu(false);
            })
            .catch((err) => {
                toast.error(`${err.message}`);
            })
            .finally(() => setLoading(false));
    }

    // const toggleMenu = (val: boolean) => {
    //     setMenu(val);
    // };

    const toggleMenu2 = (val: boolean) => {
        setMenu(val);
    };

    // function activateCustomer(id: number) {
    //     setLoading(true);
    //     devInstance
    //         .post("/Admin/EnableCustomer", {
    //             adminId: admin.userId,
    //             customerId: id,
    //         })
    //         .then(() => {
    //             toast.success("Customer has been Enabled Successfully");
    //             fetchCustomers(1);
    //             setMenu(false);
    //         })
    //         .catch((err) => toast.error(`${err.response.data}`))
    //         .finally(() => setLoading(false));
    // }

    // function deactivateCustomer(id: number) {
    //     setLoading(true);
    //     devInstance
    //         .post("/Admin/DisableCustomer", {
    //             adminId: admin.userId,
    //             customerId: id,
    //         })
    //         .then(() => {
    //             toast.success("Customer has been Disabled Successfully");
    //             fetchCustomers(1);
    //             setMenu(false);
    //         })
    //         .catch((err) => toast.error(`${err.response.data || err.message}`))
    //         .finally(() => setLoading(false));
    // }

    // const fetchCustomers = useCallback((pageNumber: number) => {
    //     setLoading(true);
    //     devInstance
    //         .get("/Admin/GetAllCustomers", {
    //             params: {
    //                 pageNumber: pageNumber,
    //             },
    //         })
    //         .then((res: any) => {
    //             setCustomers(res?.data?.data?.pageItems);
    //             console.log(res, "rrrrr");
    //         })
    //         .catch((err) => {
    //             toast.error(`${err?.message}`);
    //             setLoading(false);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // }, []);

    // const CustomerList = () => {
    //     if (customers?.length > 0) {
    //         return (
    //             <>
    //                 {customers?.slice(0, 9).map((item: any, index: number) => (
    //                     <div className="flex items-center">
    //                         <div className="basis-1/4 pl-[20px]">
    //                             <h3>{item?.customerId}</h3>
    //                         </div>
    //                         <div className="basis-1/4 text-center">
    //                             <h3>
    //                                 {item?.firstName + " " + item?.lastName}
    //                             </h3>
    //                         </div>
    //                         <div className="basis-1/4 text-center">
    //                             <h3>{item?.email}</h3>
    //                         </div>
    //                         <div className="basis-1/4 flex justify-end pr-[10px]">
    //                             <button
    //                                 disabled
    //                                 className={`bg-error capitalize w-28 disabled:opacity-70 text-center flex justify-center items-center gap-x-1 cursor-pointer p-1 text-xs rounded-md`}
    //                                 // onClick={() => {

    //                                 // }}
    //                             >
    //                                 Disable
    //                                 <span>
    //                                     <svg
    //                                         xmlns="http://www.w3.org/2000/svg"
    //                                         fill="none"
    //                                         viewBox="0 0 24 24"
    //                                         strokeWidth={1.5}
    //                                         stroke="currentColor"
    //                                         className="w-4 h-4"
    //                                     >
    //                                         <path
    //                                             strokeLinecap="round"
    //                                             strokeLinejoin="round"
    //                                             d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
    //                                         />
    //                                     </svg>
    //                                 </span>
    //                             </button>
    //                         </div>
    //                     </div>
    //                 ))}
    //             </>
    //         );
    //     } else {
    //         return (
    //             <div className="pl-[20px] text-center text-error">
    //                 <h3>No Customers Found</h3>
    //             </div>
    //         );
    //     }
    // };

    // const Modal = ({ children, close }: any) => (
    //     <div className="fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-primary/20">
    //         <div className="w-[800px] flex flex-col min-h-[400px] bg-white-light shadow-sm rounded-[20px] p-10 relative">
    //             <img
    //                 alt=""
    //                 src={closeModal}
    //                 className="w-5 absolute top-10 right-10 cursor-pointer"
    //                 onClick={close}
    //             />
    //             <>{children}</>
    //         </div>
    //     </div>
    // );

    return (
        <AdminLayout>
            <div className="pt-[50px] text-primary max-w-[1100px] text-base pb-64 mr-5">
                <h3 className="text-xl font-semibold mb-[15px]">Overview</h3>
                <div className="grid grid-cols-4 gap-5 mt-10">
                    {/* <div
                        className="min-h-[200px] border text-center text-white-lighter border-primary/10 bg-primary shadow-sm rounded-xl flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/admin/customers")}
                    >
                        <div>
                            <h3 className="font-semibold">Customers</h3>
                            <p className="text-3xl font-semibold">
                                {customerLength}
                            </p>
                        </div>
                    </div> */}
                    <div
                        className="min-h-[200px] border text-center text-white-lighter border-primary/10 bg-primary shadow-sm rounded-xl flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/admin/transactions")}
                    >
                        <div>
                            <h3 className="font-semibold">
                                Total Transactions
                            </h3>
                            <p className="text-3xl font-semibold">
                                {investments?.length}
                            </p>
                        </div>
                    </div>
                    <div
                        className="min-h-[200px] border text-center text-white-lighter border-primary/10 bg-primary shadow-sm rounded-xl flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/admin/products")}
                    >
                        <div>
                            <h3 className="font-semibold">Total Products</h3>
                            <p className="text-3xl font-semibold">
                                {products.length}
                            </p>
                        </div>
                    </div>
                    <div
                        className="min-h-[200px] border text-center text-white-lighter border-primary/10 bg-primary shadow-sm rounded-xl flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/admin/news")}
                    >
                        <div>
                            <h3 className="font-semibold">Total News</h3>
                            <p className="text-3xl font-semibold">
                                {news.length}
                            </p>
                        </div>
                    </div>
                    {/* <div
                        className="min-h-[200px] border text-center text-white-lighter border-primary/10 bg-primary shadow-sm rounded-xl flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/admin/enquiries")}
                    >
                        <div>
                            <h3 className="font-semibold">Total Enquiries</h3>
                            <p className="text-3xl font-semibold">
                                {enquiries.length}
                            </p>
                        </div>
                    </div> */}
                </div>

                {/* <div className="mt-20">
                    <h2 className="text-xl font-semibold mb-5">Customers</h2>
                    <div className="h-[365px]">
                        <div className="w-full rounded-[20px] bg-white-lighter h-full">
                            <div className="text-sm w-full rounded-[20px] bg-white-light h-[365px]">
                                <div className="flex flex-col h-[500px] overflow-hidden">
                                    <div className="flex flex-col grow overflow-y-auto">
                                        <Table2
                                            customers={customers}
                                            menu={menu}
                                            activateCustomer={activateCustomer}
                                            deactivateCustomer={
                                                deactivateCustomer
                                            }
                                            type="B"
                                            toggleMenu={toggleMenu}
                                            cid={getCustomerDetails}
                                        />
                                    </div>
                                </div>
                                <p
                                    className="font-semibold text-sm text-right mr-5 cursor-pointer mt-5"
                                    onClick={() => navigate("/admin/customers")}
                                >
                                    View more
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="mt-20">
                    <h2 className="text-xl font-semibold mb-5">Transactions</h2>
                    <div className="h-[365px]">
                        <div className="w-full rounded-[20px] bg-white-lighter h-full">
                            <div className="text-sm w-full rounded-[20px] bg-white-light h-[365px]">
                                {/* <div className="flex bg-primary rounded-[20px] h-[45.2px] text-white items-center text-sm xl:text-base py-2">
                                        <div className="basis-1/4 pl-[20px]">
                                            <h3>Type</h3>
                                        </div>
                                        <div className="basis-1/4 text-center">
                                            <h3>Amount</h3>
                                        </div>
                                        <div className="basis-1/4 text-center">
                                            <h3>Date</h3>
                                        </div>
                                        <div className="basis-1/4 text-center">
                                            <h3>Request Id</h3>
                                        </div>
                                        <div className="basis-1/4 text-center">
                                            <h3>Customer Id</h3>
                                        </div>
                                        <div className="basis-1/4 text-right pr-[20px]">
                                            <h3>Status</h3>
                                        </div>
                                    </div> */}
                                <div>
                                    <div className="flex flex-col h-[500px] overflow-x-hidden overflow-y-auto">
                                        <div className="flex flex-col">
                                            <Table
                                                transactions={investments}
                                                // prevPage={prevPage}
                                                // nextPage={nextPage}
                                                // totalPages={totalPages}
                                                // currentPage={currentPage}
                                                isAdmin
                                                approveReq={approveReq}
                                                declineReq={declineReq}
                                                menu={menu}
                                                toggleMenu={toggleMenu2}
                                                // reqId={getDetail}
                                            />
                                        </div>
                                    </div>
                                    <p
                                        className="font-semibold text-sm text-right mr-5 cursor-pointer mt-5"
                                        onClick={() =>
                                            navigate("/admin/transactions")
                                        }
                                    >
                                        View more
                                    </p>
                                </div>
                                {/* {modal && (
                                    <DetailsModal
                                        cancel={(e: any) => {
                                            // e.stopPropagation();
                                            setModal(false);
                                        }}
                                        title="Customer Details"
                                        customerDetails={
                                            customer.customerDetails
                                        }
                                        hasTransactions
                                        reqId={getDetail}
                                        transactions={customer.data.pageItems}
                                        isAdmin
                                        type="B"
                                        approveReq={approveReq}
                                        declineReq={declineReq}
                                        menu={menu2}
                                        toggleMenu={toggleMenu2}
                                    />
                                )} */}
                            </div>
                            {modal2 && (
                                <DetailsModal
                                    cancel={(e: any) => {
                                        // e.stopPropagation();
                                        setModal2(false);
                                    }}
                                    customerDetails={transaction}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loader />}
        </AdminLayout>
    );
};

export default AdminScreen;
